import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CoreState } from "./core.reducer";

const coreState = createFeatureSelector<CoreState>('core');

export const isLoading = createSelector(coreState, ({ isLoading }) => isLoading);

export const navbarTitleKey = createSelector(coreState, ({ navbarTitleKey }) => navbarTitleKey);

export const sessionExpired = createSelector(coreState, ({ sessionExpired }) => sessionExpired);
