import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  HostBinding,
  inject,
  OnDestroy,
  OnInit,
  Signal
} from '@angular/core';
import { debounceTime, distinctUntilChanged, filter, map, take } from 'rxjs';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  RouteConfigLoadStart,
  Router,
  RouterEvent,
  RouterOutlet,
} from '@angular/router';
import { SessionStorageService } from 'ngx-webstorage';
import { OperatorDTOExtended } from './core/operators/operators.model';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { LocalStorageKeysEnum } from './shared/models/enums';
import * as OperatorsActions from './core/operators/store/operators.actions';
import * as OperatorsSelectors from './core/operators/store/operators.selector';
import * as AuthActions from './core/auth/store/auth.actions';
import { CustomersApiActions } from './core/customers/store/customers.action';
import * as DeviceTypeActions from './core/device-types/store/device-types.actions';
import { FunctionsApiActions } from './core/functions/store/functions.action';
import { UmApiActions } from './core/um/store/um.action';
import * as CostCenterActions from './core/cost-center/store/cost-center.actions';
import * as RoleActions from './core/role/store/role.action';
import * as DashboardActions from './core/dashboard/store/dashboard.action';
import * as TicketStatesActions from './core/ticket-states/store/ticket-states.action';
import * as JobTypesActions from './core/job/store/job-types/job-types.actions';
import { MessageService, PrimeNGConfig, Translation } from 'primeng/api';
import * as PriorityActions from './core/priority/store/priority.actions';
import { DatoPersonalizzatoApiActions } from './core/dato-personalizzato/store/dato-personalizzato.actions';
import { filterNotNull } from './shared/utility/rxjs-utils';
import * as RouterSelectors from './core/router/store/router.selectors';
import { KeycloakService } from 'keycloak-angular';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { DocumentTypeApiActions } from './core/documents/store/document-type.actions';
import { Nullable } from './shared/models/types';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SettingsCoreAction } from './core/settings/store/settings-core.action';
import * as TicketCategoriesActions from './core/ticket-categories/store/ticket-categories.action';
import { ItemTypesApiActions } from './core/items/store/item-types.actions';
import { AbsoluteLoaderComponent } from './shared/components/loader/absolute-loader.component';
import { toSignal } from '@angular/core/rxjs-interop';
import { NavbarComponent } from "./core/components/navbar/navbar.component";
import { NewVersionCheckerService } from "./core/services/new-version-checker.service";
import { ToastModule } from "primeng/toast";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { faCircleInfo, faTimes } from '@fortawesome/free-solid-svg-icons';
import { environment as Config } from '../environments/environment';
import { IfLoggedDirective } from "./shared/directives/ifLogged.directive";
import { faWifiSlash } from "./shared/utility/fontawesome-custom";
import { slideInTop } from "./shared/utility/AnimationUtils";
import { NetworkService } from "./core/services/gui/network.service";

@UntilDestroy()
@Component({
  selector: 'ta-root',
  standalone: true,
  template: `
    <ta-loader>
      <ng-container *transloco="let translate">
        <div class="tw-flex tw-flex-col tw-size-full">
          <ng-template [taIfLogged]="this.embedded()">
            <ta-navbar></ta-navbar>
          </ng-template>
          @if(this.sistemOffline()) {
            <div [@slideInTop] class="tw-flex tw-items-center tw-justify-center tw-gap-4 tw-p-2 tw-bg-red-500/20 tw-text-red-500">
              <fa-icon [icon]="this.faWifiSlash" class="tw-animate-pulse"></fa-icon>
              <span class="tw-animate-pulse">{{ translate('systemOffline') }}</span>
            </div>
          }
          <div class="tw-flex-1 tw-bg-white tw-relative tw-overflow-hidden" [class.tw-mt-2]="!this.embedded()">
            @if (this.isLoadingRoute()) {
              <ta-absolute-loader zIndex="5"></ta-absolute-loader>
            }
            <router-outlet></router-outlet>
          </div>
          <ng-template [taIfLogged]="this.embedded()">
            <div class="tw-flex tw-justify-center tw-w-full tw-h-[30px] tw-items-center tw-text-sm tw-text-primary-hover tw-font-medium">
              <a href="http://www.tagapplication.it" target="_blank" class="hover:tw-text-primary hover:tw-underline">
                TAG Application powered by GRAF ENTERPRISE
              </a>
            </div>
          </ng-template>
        </div>
        <p-toast key="new-version-notifications" [preventDuplicates]="true">
          <ng-template let-message pTemplate="headless">
            <div class="tw-flex tw-items-center tw-p-4 tw-gap-4 tw-relative">
              <div class="tw-flex-none tw-absolute tw-right-4 tw-top-4">
                <fa-icon class="tw-cursor-pointer" [icon]="this.faTimes"
                         (click)="this.closeToast('new-version-notifications')"
                ></fa-icon>
              </div>
              <div class="tw-text-3xl">
                <fa-icon [icon]="this.faCircleInfo"></fa-icon>
              </div>
              <div class="tw-flex tw-flex-col">
                <span>{{ translate('versionInfo.newVersionAvailable') }}</span>
                <span>{{ translate('versionInfo.reloadPageToUpdate') }}</span>
              </div>
            </div>
          </ng-template>
        </p-toast>
      </ng-container>
    </ta-loader>
    <router-outlet name="modal"></router-outlet>
    <router-outlet name="second-modal"></router-outlet>
  `,
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInTop()
  ],
  providers: [MessageService],
  imports: [
    LoaderComponent, NavbarComponent, AbsoluteLoaderComponent, RouterOutlet, TranslocoDirective,
    ToastModule, FaIconComponent, IfLoggedDirective
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, OnDestroy {

  readonly #translocoService = inject(TranslocoService);
  readonly #primengConfig = inject(PrimeNGConfig);
  readonly #store = inject(Store);
  readonly #keycloak = inject(KeycloakService);
  readonly #router = inject(Router);
  readonly #sessionStorage = inject(SessionStorageService);
  readonly #newVersionCheckerService = inject(NewVersionCheckerService);
  readonly #messageService = inject(MessageService);
  readonly #networkService = inject(NetworkService);

  @HostBinding('style.--scrollbar-with')
  private scrollBarWith = 'auto';
  @HostBinding('style.--scrollbar-color')
  private scrollBarColor = 'unset';

  public readonly isLoadingRoute = toSignal(
    this.#router.events.pipe(
      filter((event: Event | RouterEvent) => {
        let showAppLoader = event instanceof NavigationEnd || event instanceof NavigationCancel;
        if (event instanceof RouteConfigLoadStart) {
          const d = event.route.data?.['showAppLoader'] as Nullable<boolean>;
          if(d != null){
            showAppLoader = d;
          }
        }
        return showAppLoader;
      }),
      debounceTime(100),
      map((event: Event | RouterEvent) => !(event instanceof NavigationEnd || event instanceof NavigationCancel)),
      distinctUntilChanged()
    ),
    { initialValue: true }
  );

  public embedded: Signal<boolean> = this.#store.selectSignal(RouterSelectors.embedded);
  public sistemOffline = computed(() => this.#networkService.isOffline());
  public loggedOperator: OperatorDTOExtended | null = null;
  public faCircleInfo = faCircleInfo;
  public faTimes = faTimes;
  public faWifiSlash = faWifiSlash;

  constructor () {
    // Messaggio di avviso per chi apre la console
    const warningTitleCSS = 'color:red; font-size:60px; font-weight: bold; -webkit-text-stroke: 1px black;';
    const warningDescCSS = 'font-size: 18px;';

    if(inject(DeviceDetectorService).browser === 'Firefox'){
      this.scrollBarWith = 'thin';
      this.scrollBarColor = '#afb8bd #ffffff';
    }

    /* eslint-disable no-console */
    console.log('%cStop!', warningTitleCSS);
    console.log('%cThis is a browser feature intended for developers. If someone told you to copy and paste something here to enable a feature or "hack" system, it is a scam. Interacting with the console violates our system policies.', warningDescCSS);
    console.log('%cQuesta è una funzionalità per sviluppatori. Se qualcuno ti ha detto di copiare e incollare qualcosa qui per attivare una feature o per "hackerare" il sistema, è uno scam. Interagire con la console viola le nostre policy di sistema.', warningDescCSS);

    effect(() => {
      if(this.#newVersionCheckerService.latestVersionHash()){
        this.#messageService.add({ key: 'new-version-notifications', severity: 'info', sticky: true });
      }
    }, { allowSignalWrites: true });
  }

  public ngOnInit (): void {
    this.#store.select(OperatorsSelectors.loggedOperator)
      .pipe(filterNotNull, take(1))
      .subscribe(operator => {
        this.loggedOperator = operator;
        // TODO DA VALUTARE SE SPOSTARE NELLA ACTION LOGIN SUCCESS
        this.initStore();
      });

    const loggedIn: boolean = this.#keycloak.isLoggedIn();
    if (!loggedIn) {
      this.#sessionStorage.store(LocalStorageKeysEnum.firstLogin, true);
      this.#sessionStorage.store(LocalStorageKeysEnum.urlToOpenIfLogged, location.href.replace(`${location.origin}${Config.baseHref}`, ''));
      this.#keycloak.login({ redirectUri: location.href });
    }

    this.#primengConfig.ripple = true;
    this.#translocoService.selectTranslateObject('primeng')
      .pipe(untilDestroyed(this))
      .subscribe((translate: Translation) => {
        this.#primengConfig.setTranslation(translate);
      });
  }

  public ngOnDestroy (): void {
    this.#store.dispatch(AuthActions.logout());
  }

  public closeToast (key: string): void {
    this.#messageService.clear(key);
  }

  // Inizializzo i dati nello store
  private initStore (): void {
    this.#store.dispatch(CustomersApiActions.customersForLoggedOperator());
    this.#store.dispatch(OperatorsActions.retrieveAll());
    this.#store.dispatch(CustomersApiActions.allCustomers());
    this.#store.dispatch(PriorityActions.getAll());
    this.#store.dispatch(DeviceTypeActions.retrieveAll());
    this.#store.dispatch(FunctionsApiActions.getAll());
    this.#store.dispatch(FunctionsApiActions.getAllForOperator());
    this.#store.dispatch(UmApiActions.getAll());
    this.#store.dispatch(CostCenterActions.getAll());
    this.#store.dispatch(RoleActions.getAllWebOperations());
    this.#store.dispatch(DashboardActions.getAllByOpeId());
    this.#store.dispatch(TicketStatesActions.getAll());
    this.#store.dispatch(DatoPersonalizzatoApiActions.allTemplateDatoPers());
    this.#store.dispatch(JobTypesActions.allJobTypes());
    this.#store.dispatch(DocumentTypeApiActions.getAll());
    this.#store.dispatch(SettingsCoreAction.getDocumentsSettings());
    this.#store.dispatch(SettingsCoreAction.getTasksSettings());
    this.#store.dispatch(SettingsCoreAction.getTicketSettings());
    this.#store.dispatch(TicketCategoriesActions.retrieveAll());
    this.#store.dispatch(ItemTypesApiActions.getAllItemTypes());
  }

}
