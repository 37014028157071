import { IconDefinition } from "@fortawesome/free-regular-svg-icons";

export const faGrid: IconDefinition = {
  prefix: "fas",
  iconName: "grid",
  icon: [488,512,[],'','M0 72c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40V72zm0 160c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40v-48zm128 160v48c0 22.1-17.9 40-40 40H40c-22.1 0-40-17.9-40-40v-48c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40zm32-320c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40V72zm128 160v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40v-48c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40zM160 392c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40v-48zM448 72v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40V72c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40zM320 232c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40v-48zm128 160v48c0 22.1-17.9 40-40 40h-48c-22.1 0-40-17.9-40-40v-48c0-22.1 17.9-40 40-40h48c22.1 0 40 17.9 40 40z']
};

export const faNcIco: IconDefinition = {
  prefix: "fas",
  // @ts-expect-error - .
  iconName: "nc",
  icon: [512,512,[],'','m405 85l-89 0c-9-24-32-42-60-42-28 0-51 18-60 42l-89 0c-24 0-43 19-43 43l0 299c0 23 19 42 43 42l298 0c24 0 43-19 43-42l0-299c0-24-19-43-43-43z m-170 299l-107 0 0-43 107 0z m0-128l-107 0 0-43 107 0z m21-128c-12 0-21-10-21-21 0-12 9-22 21-22 12 0 21 10 21 22 0 11-9 21-21 21z m128 247l-30 30-23-23-23 23-31-30 24-23-24-23 31-30 23 23 23-23 30 30-23 23z m-55-119l-52 0 0-43 34 0 43-42 30 30z']
};

export const faWifiSlash: IconDefinition = {
  prefix: "fas",
  iconName: "wifi-slash",
  icon: [640,512,[],'','M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L364.6 260.4c39.2 7.9 74.7 26.1 103.6 51.6c13.3 11.7 33.5 10.4 45.2-2.8s10.4-33.5-2.8-45.2C459.8 219.2 393 192 320 192c-13.3 0-26.5 .9-39.3 2.7l-95.1-74.5C227.4 104.5 272.7 96 320 96c103.2 0 196.8 40.7 265.8 106.9c12.8 12.2 33 11.8 45.2-.9s11.8-33-.9-45.2C549.7 79.5 440.4 32 320 32c-68.7 0-133.7 15.5-191.9 43.1L38.8 5.1zM380.8 396l-55.6-43.8c-1.7-.1-3.4-.2-5.2-.2c-35.3 0-64 28.7-64 64s28.7 64 64 64s64-28.7 64-64c0-7-1.1-13.7-3.2-20zM228 275.7L173 232.3c-15.5 9.2-30.1 19.8-43.5 31.7c-13.3 11.7-14.5 31.9-2.8 45.2s31.9 14.5 45.2 2.8c16.7-14.8 35.6-27 56.2-36.3zM93.6 169.8L41.7 128.9C30.7 137.7 20 147 9.8 156.7C-2.9 169-3.3 189.2 8.9 202s32.5 13.2 45.2 .9c12.4-11.9 25.6-23 39.5-33.1z']
};

export const faWifiExclamation: IconDefinition = {
  prefix: "fas",
  iconName: "wifi-exclamation",
  icon: [640,512,[],'','M320 32c-27.2 0-48.7 23.1-46.8 50.2l14.9 208C289.3 307 303.2 320 320 320s30.7-13 31.9-29.7l14.9-208C368.7 55.1 347.2 32 320 32zM241.3 84.5c-1.2-17.4 3.3-33.9 11.9-47.6C159.4 51 75.1 94.1 9.8 156.7C-2.9 169-3.3 189.2 8.9 202s32.5 13.2 45.2 .9c51.3-49.2 116.2-84.3 188.5-99.1l-1.4-19.3zm157.4 0l-1.4 19.3c72.3 14.8 137.2 49.9 188.5 99.1c12.8 12.2 33 11.8 45.2-.9s11.8-33-.9-45.2C564.9 94.1 480.6 51 386.8 37c8.6 13.7 13.1 30.1 11.9 47.6zM129.5 264c-13.3 11.7-14.5 31.9-2.8 45.2s31.9 14.5 45.2 2.8c23.6-20.8 51.6-36.7 82.4-46.2l-4.7-65.1C204.4 212 163.4 234.1 129.5 264zm260.9-63.3l-4.6 65.1c30.8 9.4 58.8 25.4 82.4 46.2c13.3 11.7 33.5 10.4 45.2-2.8s10.4-33.5-2.8-45.2c-33.9-29.9-74.9-52-120.1-63.3zM384 416a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z']
};

export const faSyncExclamation: IconDefinition = {
  prefix: "fas",
  // @ts-expect-error - .
  iconName: "sync-exclamation",
  icon: [512,512,[],'','m74.9 192-28.8-13.8c-7.6 15.9-.9 35 15 42.7s35 .9 42.7-15v-.1l.2-.3.7-1.5c.6-1.3 1.6-3.2 2.8-5.6 2.5-4.8 5.9-11.4 10-18.5 8.7-15.3 18.2-29.8 25.3-36.9 62.5-62.5 163.8-62.5 226.3 0l17.2 17H352c-17.7 0-32 14.3-32 32s14.3 32 32 32h112c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2l-17.6-17.6c-87.5-87.5-229.3-87.5-316.8 0-13.8 13.8-27 35.2-35.7 50.6-4.6 8.2-8.6 15.6-11.3 21-1.4 2.7-2.5 4.9-3.3 6.5l-.9 1.8-.2.5-.1.2L74.9 192zM280 152c0-13.3-10.7-24-24-24s-24 10.7-24 24v112c0 13.3 10.7 24 24 24s24-10.7 24-24V152zm-24 232a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM48 464c17.7 0 32-14.3 32-32v-35.2l17.6 17.6c87.5 87.5 229.3 87.5 316.8 0 24.4-24.4 42.1-53.1 52.9-83.7 5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8-62.5 62.5-163.8 62.5-226.3 0L125.7 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48c-17.7 0-32 14.3-32 32v112c0 17.7 14.3 32 32 32z']
};
