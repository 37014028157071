import LocaleHelper from '@bryntum/core-thin/lib/localization/LocaleHelper.js';
import '@bryntum/scheduler-thin/lib/localization/Es.js';
const locale = {
  localeName: 'Es',
  localeDesc: 'Español',
  localeCode: 'es',
  EventEdit: {
    Calendar: 'Calendario',
    'All day': 'Todo el día',
    day: 'Día',
    week: 'Semana',
    month: 'Mes',
    year: 'Año',
    decade: 'Década'
  },
  EventMenu: {
    duplicateEvent: 'Duplicar evento',
    copy: 'copiar'
  },
  Calendar: {
    toggleSidebar: 'Alternar visibilidad de la barra lateral',
    Today: 'Hoy',
    Tomorrow: '',
    next: range => `Siguiente ${range}`,
    previous: range => `Anterior ${range}`,
    plusMore: value => `+${value} más`,
    allDay: 'Todo el día',
    endsOn: d => `Termina el ${d}`,
    weekOfYear: ([y, w]) => `Semana ${w}, ${y}`,
    loadFail: 'Fallo al cargar los datos del calendario. Contacte con el administrador de su sistema'
  },
  CalendarDrag: {
    holdCtrlForRecurrence: 'Presionar CTRL para un evento recurrente'
  },
  CalendarMixin: {
    eventCount: count => `${count || 'Sin'} evento${count && count > 1 ? 's' : ''}`
  },
  EventTip: {
    'Edit event': 'Editar evento',
    timeFormat: 'LST'
  },
  ModeSelector: {
    includeWeekends: 'Incluir fines de semana',
    weekends: 'Fines de semana'
  },
  AgendaView: {
    Agenda: 'Agenda'
  },
  MonthView: {
    Month: 'Mes',
    monthUnit: 'mes'
  },
  WeekView: {
    weekUnit: 'semana'
  },
  YearView: {
    Year: 'Año',
    yearUnit: 'año',
    noEvents: 'No hay eventos'
  },
  EventList: {
    List: 'Lista',
    Start: 'Inicio',
    Finish: 'Finalización',
    days: count => `${count > 1 ? `${count} ` : ''}día${count === 1 ? '' : 's'}`
  },
  DayView: {
    Day: 'Día',
    dayUnit: 'día',
    daysUnit: 'días',
    expandAllDayRow: 'Expandir sección de todo el día',
    collapseAllDayRow: 'Contraer sección de todo el día',
    timeFormat: 'LST',
    timeAxisTimeFormat: 'LST',
    noMatchingDates: 'No hay fechas coincidentes'
  },
  DayAgendaView: {
    dayAgenda: 'Agenda del día'
  },
  DayResourceView: {
    dayResourceView: 'Recursos diarios'
  },
  Sidebar: {
    'Filter events': 'Filtrar eventos'
  },
  WeekExpander: {
    expandTip: 'Hacer clic para expandir fila',
    collapseTip: 'Hacer clic para contraer fila'
  }
};
export default LocaleHelper.publishLocale(locale);